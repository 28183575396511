<template>
  <v-layout>
    <MasterdataAppBar />

    <v-container fluid class="px-0 pt-5">
      <v-col cols="11" xl="8" lg="9" md="11" class="mx-auto px-0 pt-0">
        <v-row dense class="align-center justify-center pb-5">
          <v-card tile outlined class="flex-grow-1">
            <v-row dense align="center">
              <v-col cols="12">
                <v-card-title>
                  <v-col cols="12">
                    <span>Bitte auswählen</span>
                    <v-row dense align="center">
                      <v-col cols="12" xl="5" lg="6" md="6" sm="6">
                        <span class="subtitle-2 font-weight-bold">Abteilung</span>
                        <v-select
                          hide-details
                          dense
                          outlined
                          required
                          open-on-clear
                          v-model="selectedAbteilung"
                          :items="Abteilungen"
                          item-text="Abteilung"
                          item-value="Abteilung_ID"
                          placeholder="Abteilung"
                          clearable
                          return-object
                          @click:clear="clearSelectedGroup()"
                          @change="resetSelectedAbteilung()"
                          ><template v-slot:no-data>
                            <span class="text-center d-block">keine Abteilung gefunden</span>
                          </template>
                        </v-select>
                      </v-col>
                      <v-col cols="12" xl="5" lg="6" md="6" sm="6">
                        <span class="subtitle-2 font-weight-bold">Artikelgruppe</span>
                        <v-select
                          hide-details
                          dense
                          outlined
                          required
                          open-on-clear
                          v-model="selectedArtikelgruppe"
                          :items="possibleGroups"
                          item-text="Artikelgruppe"
                          item-value="Artikelgruppe_ID"
                          placeholder="Artikelgruppe"
                          clearable
                          return-object
                          @change="resetSelectedArtikelgruppe()"
                          :disabled="!selectedAbteilung"
                          ><template v-slot:no-data>
                            <span class="text-center d-block">keine Gruppe gefunden</span>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-card-title>
              </v-col>
            </v-row>
          </v-card>
        </v-row>
        <v-row dense class="align-center justify-center">
          <v-card tile outlined class="flex-grow-1">
            <v-col cols="12">
              <v-card-title class="p-2">
                <v-row dense
                  ><v-col cols="12">
                    <v-toolbar-title>
                      Artikeluntergruppen
                      <span v-if="selectedArtikelgruppe">{{
                        "(" + selectedAbteilung.Abteilung + " / " + selectedArtikelgruppe.Artikelgruppe + ")"
                      }}</span>
                    </v-toolbar-title>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-divider inset v-if="selectedArtikelgruppe"></v-divider>
              <v-card-text v-if="selectedArtikelgruppe" class="subtitle-2 px-1">
                <v-row dense class="align-center justify-center">
                  <v-col cols="12">
                    <v-card tile outlined class="flex-grow-1">
                      <v-col cols="12">
                        <v-card-title class="px-0">
                          <v-row dense align="center">
                            <v-col cols="12">
                              <v-row dense align="center">
                                <v-col cols="auto">
                                  <v-text-field
                                    dense
                                    hide-details
                                    v-model="filter.search"
                                    prepend-icon="mdi-magnify"
                                    clearable
                                    placeholder="Suche"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-card-title>
                        <v-divider inset></v-divider>
                        <v-card-text class="px-2 subtitle-2">
                          <v-row dense>
                            <v-col cols="12">
                              <v-data-table
                                v-model="selected"
                                height="calc(48px + (48px * 5))"
                                fixed-header
                                hide-default-footer
                                :items="filteredByGroup"
                                item-key="Artikeluntergruppe_ID"
                                :items-per-page="25"
                                :page.sync="page"
                                @page-count="pageCount = $event"
                                :headers="datasetHeaders"
                                :search="filter.search"
                                :single-select="true"
                                show-select
                                @item-selected="assignDataset"
                                mobile-breakpoint="300"
                                style="width: 100%;"
                              >
                                <template #[`item.Artikeluntergruppe`]="{ item }">
                                  <span class="font-weight-bold"> {{ item.Artikeluntergruppe }}</span>
                                </template>
                              </v-data-table>
                            </v-col>
                          </v-row>
                        </v-card-text>
                        <v-divider inset></v-divider>
                        <v-card-actions>
                          <v-row dense align="center">
                            <v-col cols="12">
                              <v-pagination color="grey darken-2" v-model="page" :length="pageCount"></v-pagination>
                            </v-col>
                          </v-row>
                        </v-card-actions>
                      </v-col>
                    </v-card>
                  </v-col>
                  <v-col cols="12" xl="5" lg="6" md="6" sm="8">
                    <v-row>
                      <v-col cols="12" v-if="selected.length == 0">
                        <v-row dense>
                          <v-col cols="12">
                            <span class="font-weight-bold">Artikeluntergruppe</span>
                            <v-text-field
                              v-model="newDataset.Artikeluntergruppe"
                              hide-details
                              placeholder="Artikeluntergruppe"
                              color="primary"
                              outlined
                              clearable
                              :error="checkArtikeluntergruppe"
                              ><template v-slot:append>
                                <v-icon v-if="!checkArtikeluntergruppe && newDataset.Artikeluntergruppe" color="green lighten-2">mdi-check</v-icon>
                                <v-icon v-else-if="checkArtikeluntergruppe && newDataset.Artikeluntergruppe" color="red lighten-2">mdi-alert</v-icon>
                              </template>
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" class="pt-4">
                            <v-btn
                              @click="speichern(newDataset)"
                              block
                              color="green lighten-2 grey--text text--lighten-4"
                              :disabled="!newDataset.Artikeluntergruppe || checkArtikeluntergruppe"
                            >
                              <v-icon>mdi-plus</v-icon>
                              <span class="ml-1">Neu</span>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" v-else>
                        <v-row dense>
                          <v-col cols="12">
                            <span class="font-weight-bold">Artikeluntergruppe</span>
                            <v-text-field
                              v-model="editableDataset.Artikeluntergruppe"
                              hide-details
                              placeholder="Artikeluntergruppe"
                              color="primary"
                              outlined
                              clearable
                              :error="checkArtikeluntergruppe"
                              ><template v-slot:append>
                                <v-icon v-if="!checkArtikeluntergruppe && editableDataset.Artikeluntergruppe" color="green lighten-2"
                                  >mdi-check</v-icon
                                >
                                <v-icon v-else-if="checkArtikeluntergruppe && editableDataset.Artikeluntergruppe" color="red lighten-2"
                                  >mdi-alert</v-icon
                                >
                              </template>
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" class="pt-4">
                            <v-btn
                              block
                              color="orange lighten-2 grey--text text--lighten-4"
                              @click="speichern(editableDataset)"
                              :disabled="!editableDataset.Artikeluntergruppe || checkArtikeluntergruppe"
                            >
                              <v-icon>mdi-pencil</v-icon>
                              <span class="ml-1">Bearbeiten</span>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-card>
        </v-row>
      </v-col>
    </v-container>
  </v-layout>
</template>

<script>
import MasterdataAppBar from "../../../components/appbars/MasterdataAppBar.vue";

export default {
  components: {
    MasterdataAppBar,
  },

  mounted() {
    this.initialize();
  },

  data() {
    return {
      filter: {
        search: "",
      },
      datasetHeaders: [],
      hiddenColumns: ["Artikelgruppe_ID", "Artikeluntergruppe_ID", "Artikelgruppe"],
      datasets: [],
      Abteilungen: [],
      Artikelgruppen: [],
      selectedAbteilung: null,
      selectedArtikelgruppe: null,
      page: 1,
      pageCount: 0,
      selected: [],
      newDataset: {},
      editableDataset: {},
    };
  },

  computed: {
    filteredByGroup() {
      return this.datasets.filter((item) => item.Artikelgruppe_ID === this.selectedArtikelgruppe.Artikelgruppe_ID);
    },

    possibleGroups() {
      if (this.selectedAbteilung) {
        return this.Artikelgruppen.filter((item) => item.Abteilung_ID === this.selectedAbteilung.Abteilung_ID);
      } else return [];
    },

    checkArtikeluntergruppe() {
      const findname = this.datasets.filter(
        (item) =>
          (item.Artikeluntergruppe === this.newDataset.Artikeluntergruppe || item.Artikeluntergruppe === this.editableDataset.Artikeluntergruppe) &&
          item.Artikelgruppe_ID === this.selectedArtikelgruppe.Artikelgruppe_ID
      );
      if (findname.length > 0) {
        return true;
      } else return false;
    },
  },

  methods: {
    async initialize() {
      const response = await fetch(`/api/settings/masterdata/subgroups`);
      const json = await response.json();

      for (let [key, value] of Object.entries(json)) {
        this[key] = value;
      }

      const filterableHeaders = [
        true, //Artikeluntergruppe
      ];
      const alignmentHeaders = ["start"];
      const dividerHeaders = [false];

      this.datasetHeaders = Object.keys(this.datasets[0])
        .filter((it) => this.hiddenColumns.indexOf(it) === -1)
        .map((it, i) => {
          return {
            text: it,
            value: it,
            sortable: false,
            filterable: filterableHeaders[i],
            align: alignmentHeaders[i],
            divider: dividerHeaders[i],
            class: "text--primary text-uppercase",
          };
        });
    },

    assignDataset({ item, value }) {
      if (value == true) {
        this.editableDataset = Object.assign({}, item);
        this.newDataset = {};
      } else this.editableDataset = {};
    },

    resetSelectedAbteilung() {
      this.selectedArtikelgruppe = null;
      this.selected = [];
      this.editableDataset = {};
      this.newDataset = {};
    },

    resetSelectedArtikelgruppe() {
      this.selected = [];
      this.editableDataset = {};
      this.newDataset = {};
    },

    clearSelectedGroup() {
      this.selectedArtikelgruppe = null;
      this.selected = [];
      this.editableDataset = {};
      this.newDataset = {};
    },

    async speichern(dataset) {
      if (!dataset.Artikeluntergruppe_ID) {
        const datasetToInsert = {};

        if (Object.keys(datasetToInsert).length === 0) {
          Object.assign(datasetToInsert, {
            Artikelgruppe_ID: this.selectedArtikelgruppe.Artikelgruppe_ID,
            Artikeluntergruppe: dataset.Artikeluntergruppe,
          });
        }

        await fetch("/api/settings/masterdata/subgroup/dataset/insert", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(datasetToInsert),
        });

        this.$root.actionSnackbar.show({
          dataset: "Artikeluntergruppe",
          action: "erstellt",
        });
      } else if (dataset.Artikeluntergruppe_ID) {
        const datasetToUpdate = {};

        if (Object.keys(datasetToUpdate).length === 0) {
          Object.assign(datasetToUpdate, {
            Artikeluntergruppe_ID: dataset.Artikeluntergruppe_ID,
            Artikelgruppe_ID: dataset.Artikelgruppe_ID,
            Artikeluntergruppe: dataset.Artikeluntergruppe,
          });
        }

        await fetch("/api/settings/masterdata/subgroup/dataset/update", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(datasetToUpdate),
        });

        this.$root.actionSnackbar.show({
          dataset: "Artikeluntergruppe",
          action: "bearbeitet",
        });
      }

      this.editableDataset = {};
      this.newDataset = {};
      this.selected = [];
      this.initialize();
    },
  },
};
</script>
